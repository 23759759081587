import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  Link,
  LinearProgress,
} from '@mui/material'
import { useAppDispatch } from 'src/lib/store'
import { setBreadcrumbs } from 'src/lib/store/features/breadcrumbsSlice'
import { GET_ACCOUNTS, GetAccountsData } from 'src/lib/graphql/accounts'
import {
  GET_RUNNER_STATUSES,
  GetRunnerStatusesData,
  GetRunnerStatusesVars,
  RUNNER_STATUS_UPDATED,
  RunnerStatusUpdatedData,
} from 'src/lib/graphql/runnerStatuses'
import LogUrlTableCell from 'src/components/tablecells/LogUrlTableCell'
import RepositoryTableCell from 'src/components/tablecells/RepositoryTableCell'
import StartRunnerModal from 'src/components/StartRunnerModal'
import StatusTableCell from 'src/components/tablecells/StatusTableCell'
import DiffModal from 'src/components/DiffModal'
import Timestamp from 'src/components/Timestamp'
import PageLoading from 'src/components/PageLoading'
import { RunnerStatus } from 'src/lib/graphql/types'

export default function AccountRegionPage() {
  const { accountId, region } = useParams()
  const location = useLocation()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { displayName: `Accounts`, pathName: '/accounts' },
        { displayName: `${accountId} / ${region}`, pathName: location.pathname },
      ])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    subscribeToMore,
    loading: getRunnerStatusesLoading,
    data: getRunnerStatusesData,
    error: getRunnerStatusesError,
  } = useQuery<GetRunnerStatusesData, GetRunnerStatusesVars>(GET_RUNNER_STATUSES, {
    variables: {
      accountId: accountId as string,
      region: region as string,
    },
    fetchPolicy: 'cache-and-network',
  })

  const runnerStatuses = getRunnerStatusesData?.getRunnerStatuses.items ?? []

  if (!runnerStatuses.length && getRunnerStatusesLoading) {
    return <PageLoading />
  }

  if (getRunnerStatusesError) {
    return <div>Got an error...</div>
  }

  subscribeToMore<RunnerStatusUpdatedData>({
    document: RUNNER_STATUS_UPDATED,
    variables: { accountId: accountId as string, region: region as string },
  })

  return (
    <>
      <TableContainer sx={{ maxWidth: 300, position: 'relative' }} component={Paper} elevation={2}>
        <Table aria-label="accounts table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h5">Account</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h5">Region</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <AccountIdTableCell accountId={accountId as string} />
              <TableCell>{region}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Statuses loading={getRunnerStatusesLoading} runnerStatuses={runnerStatuses} />
    </>
  )
}

interface AccountIdTableCellProps {
  accountId: string
}

const AccountIdTableCell = ({ accountId }: AccountIdTableCellProps) => {
  const { data } = useQuery<GetAccountsData>(GET_ACCOUNTS)
  return (
    <TableCell>
      {accountId}{' '}
      <Typography style={{ fontWeight: 600 }}>
        {data?.getAccounts.items.find((a) => a.id === accountId)?.name}
      </Typography>
    </TableCell>
  )
}

interface StatusesProps {
  loading: boolean
  runnerStatuses: RunnerStatus[]
}

const Statuses = ({ loading, runnerStatuses }: StatusesProps) => {
  return (
    <Box sx={{ mt: 3 }}>
      <TableContainer component={Paper} elevation={2} sx={{ position: 'relative' }}>
        {loading && <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0 }} />}
        <Table sx={{ minWidth: 500 }} aria-label="accounts table">
          <TableHead>
            <TableRow>
              <TableCell>ProjectName</TableCell>
              <TableCell>Repository</TableCell>
              <TableCell>Source revision deployed </TableCell>
              <TableCell>Latest commit (master)</TableCell>
              <TableCell>Log url</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell colSpan={3}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <StatusesTableBody disabled={loading} runnerStatuses={runnerStatuses} />
        </Table>
      </TableContainer>
    </Box>
  )
}

interface StatusesTableBodyProps {
  disabled: boolean
  runnerStatuses: RunnerStatus[]
}

const StatusesTableBody = ({ disabled, runnerStatuses }: StatusesTableBodyProps) => {
  const sortedRunnerStatuses = [...runnerStatuses].sort((a, b) => {
    if (a.projectName < b.projectName) {
      return -1
    }

    if (a.projectName > b.projectName) {
      return 1
    }

    return 0
  })

  return (
    <TableBody>
      {sortedRunnerStatuses.map((s) => {
        return <StatusesTableRow key={`${s.accountId},${s.projectName}`} disabled={disabled} runnerStatus={s} />
      })}
    </TableBody>
  )
}

interface StatusesTableRowProps {
  disabled: boolean
  runnerStatus: RunnerStatus
}

function StatusesTableRow({ disabled, runnerStatus: s }: StatusesTableRowProps) {
  const [openStartRunnerModal, setOpenStartRunnerModal] = useState(false)
  const handleOpenStartRunnerModal = () => setOpenStartRunnerModal(true)
  const handleCloseStartRunnerModal = () => setOpenStartRunnerModal(false)

  const [openDiffModal, setOpenDiffModal] = useState(false)
  const handleOpenDiffModal = () => setOpenDiffModal(true)
  const handleCloseDiffModal = () => setOpenDiffModal(false)

  const inProgress = s.status === 'IN_PROGRESS'
  const latestMasterCommitHash = s.Project.Repository.latestMasterCommitHash ?? ''
  const masterRevDeployed = latestMasterCommitHash === s.sourceVersion
  return (
    <TableRow>
      <TableCell sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <Link component={RouterLink} to={`/projects/${s.projectName}`}>
          {s.projectName}
        </Link>
      </TableCell>
      <RepositoryTableCell repositoryUrl={s.Project.Repository.repositoryUrl} />
      <TableCell>
        <Tooltip title={s.sourceVersion ? s.sourceVersion : ''}>
          <span>{s.sourceVersion && s.sourceVersion.substring(0, 7)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={latestMasterCommitHash}>
          <span>{latestMasterCommitHash.substring(0, 7)}</span>
        </Tooltip>
      </TableCell>
      <LogUrlTableCell logUrl={s.logUrl} />
      <StatusTableCell status={s.status} />
      <TableCell>
        <Timestamp timestamp={s.timestamp} />
      </TableCell>
      <TableCell align="center">
        <StartRunnerModal
          open={openStartRunnerModal}
          handleClose={handleCloseStartRunnerModal}
          accountId={s.accountId}
          projectName={s.projectName}
          region={s.region}
          latestImage={s.Project.LatestImage}
          images={s.Project.Repository.Images}
        />
        <Button
          disabled={disabled || inProgress}
          size="small"
          variant="contained"
          color="success"
          onClick={handleOpenStartRunnerModal}
        >
          Deploy
        </Button>
      </TableCell>
      <TableCell align="center">
        <DiffModal
          open={openDiffModal}
          handleClose={handleCloseDiffModal}
          deployedCommit={s.sourceVersion}
          repository={s.Project.Repository}
        />
        <Button disabled={masterRevDeployed} size="small" variant="contained" onClick={handleOpenDiffModal}>
          Diff
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button
          component={RouterLink}
          to={`/runners/${s.accountId}/${s.region}/${s.projectName}`}
          size="small"
          variant="outlined"
          color="secondary"
        >
          History
        </Button>
      </TableCell>
    </TableRow>
  )
}
