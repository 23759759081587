import { gql } from '@apollo/client'

export const startRunner = gql`
  mutation startRunner(
    $accountId: String!
    $projectName: String!
    $region: String!
    $sourceVersion: String!
    $deploymentId: String
  ) {
    startRunner(
      input: {
        accountId: $accountId
        projectName: $projectName
        region: $region
        sourceVersion: $sourceVersion
        deploymentId: $deploymentId
      }
    )
  }
`
