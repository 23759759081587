import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  LinearProgress,
  Link,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  GET_RUNNER_STATUSES,
  GetRunnerStatusesData,
  GetRunnerStatusesVars,
  RUNNER_STATUS_UPDATED,
  RunnerStatusUpdatedData,
} from 'src/lib/graphql/runnerStatuses'
import { useAppDispatch } from 'src/lib/store'
import { setBreadcrumbs } from 'src/lib/store/features/breadcrumbsSlice'
import PageLoading from 'src/components/PageLoading'
import Timestamp from 'src/components/Timestamp'
import StatusTableCell from 'src/components/tablecells/StatusTableCell'
import DiffModal from 'src/components/DiffModal'
import { AccountType, RunnerStatus } from 'src/lib/graphql/types'
import MultiStartRunnerModal, { AccountRegion, isAccountEquals } from 'src/components/MultiStartRunnerModal'
import { ReactComponent as AwsIcon } from 'src/images/icons/aws.svg'

export default function ProjectPage() {
  const params = useParams()
  const projectName = params.projectName as string

  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { displayName: 'Projects', pathName: '/projects' },
        { displayName: projectName, pathName: location.pathname },
      ])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    subscribeToMore,
    loading: getRunnerStatusesLoading,
    data: getRunnerStatusesData,
    error: getRunnerStatusesError,
  } = useQuery<GetRunnerStatusesData, GetRunnerStatusesVars>(GET_RUNNER_STATUSES, {
    variables: {
      projectName: projectName,
    },
    fetchPolicy: 'cache-and-network',
  })

  subscribeToMore<RunnerStatusUpdatedData>({
    document: RUNNER_STATUS_UPDATED,
    variables: { projectName: projectName },
  })

  const runnerStatuses = getRunnerStatusesData?.getRunnerStatuses.items ?? []

  if (!runnerStatuses.length && getRunnerStatusesLoading) return <PageLoading />
  if (getRunnerStatusesError) return <div>Got an error...</div>

  return (
    <>
      <Typography variant="h4" component="h1" sx={{ textTransform: 'capitalize' }}>
        {projectName.replace('-', ' ')}
      </Typography>

      <ProjectTable projectName={projectName} runnerStatuses={runnerStatuses} loading={getRunnerStatusesLoading} />
    </>
  )
}

interface ProjectTableProps {
  projectName: string
  runnerStatuses: RunnerStatus[]
  loading: boolean
}

const ProjectTable = ({ projectName, runnerStatuses, loading }: ProjectTableProps) => {
  const [deployModal, setDeployModal] = useState(false)
  const openDeployModal = () => setDeployModal(true)
  const closeDeployModal = () => setDeployModal(false)
  const [selected, setSelected] = useState<AccountRegion[]>([])

  const sections: Sections = {
    [AccountType.WIP]: [],
    [AccountType.INTERNAL]: [],
    [AccountType.CUSTOMER_TEST]: [],
    [AccountType.CUSTOMER_PROD]: [],
  }

  const sortedRunnerStatuses = [...runnerStatuses].sort((a, b) => {
    const accountNameA = a.Account.name
    const accountNameB = b.Account.name

    if (accountNameA < accountNameB) {
      return -1
    }

    if (accountNameA > accountNameB) {
      return 1
    }

    return 0
  })

  sortedRunnerStatuses.reduce((acc, curr) => {
    acc[curr.Account.type].push(curr)
    return acc
  }, sections)

  const isSelected = (acc: AccountRegion) => selected.findIndex((a) => isAccountEquals(acc, a)) !== -1

  const onSelectRow = (selectedAccount: AccountRegion) => {
    if (isSelected(selectedAccount)) {
      setSelected([...selected].filter((a) => !isAccountEquals(a, selectedAccount)))
      return
    }

    setSelected([...selected, selectedAccount])
  }

  const selectedSection = (type: AccountType) => selected.filter((a) => a.type === type)

  const onSelectAll = (type: AccountType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const numSelected = selectedSection(type).length
    const numRunners = sections[type].length
    const indeterminate = numSelected > 0 && numSelected < numRunners

    const other = selected.filter((rs) => rs.type !== type)

    // select all for the matching AccountType
    if (event.target.checked && !indeterminate) {
      const test = runnerStatuses.reduce<AccountRegion[]>((prev: AccountRegion[], curr: RunnerStatus) => {
        if (curr.Account.type !== type) {
          return prev
        }
        if (curr.status === 'IN_PROGRESS') {
          return prev
        }

        return [...prev, toAccountRegion(curr)]
      }, [])

      setSelected([...other, ...test])
      return
    }

    // de-select all of the matching AccountType
    setSelected(other)
  }

  const onDeployed = () => {
    setSelected([])
  }

  const image = {
    tag: 'string',
    digest: 'string',
    timestamp: 'string',
  }

  return (
    <Box sx={{ mb: '60px' }}>
      <StatusesTable
        title="Internal"
        loading={loading}
        runnerStatuses={sections.INTERNAL}
        selected={selectedSection(AccountType.INTERNAL)}
        isSelected={isSelected}
        onSelectRow={onSelectRow}
        onSelectAll={onSelectAll(AccountType.INTERNAL)}
      />
      <StatusesTable
        title="Test"
        loading={loading}
        runnerStatuses={sections.CUSTOMER_TEST}
        selected={selectedSection(AccountType.CUSTOMER_TEST)}
        isSelected={isSelected}
        onSelectRow={onSelectRow}
        onSelectAll={onSelectAll(AccountType.CUSTOMER_TEST)}
      />
      <StatusesTable
        title="Production"
        loading={loading}
        runnerStatuses={sections.CUSTOMER_PROD}
        selected={selectedSection(AccountType.CUSTOMER_PROD)}
        isSelected={isSelected}
        onSelectRow={onSelectRow}
        onSelectAll={onSelectAll(AccountType.CUSTOMER_PROD)}
      />
      <StatusesTable
        title="WIP"
        loading={loading}
        runnerStatuses={sections.WIP}
        selected={selectedSection(AccountType.WIP)}
        isSelected={isSelected}
        onSelectRow={onSelectRow}
        onSelectAll={onSelectAll(AccountType.WIP)}
      />

      <MultiStartRunnerModal
        open={deployModal}
        projectName={projectName}
        accounts={selected}
        latestImage={image}
        images={[image]}
        onClose={closeDeployModal}
        onDeployed={onDeployed}
      />
      <SectionToolbar title={projectName} numSelected={selected.length} onDeployClick={openDeployModal} />
    </Box>
  )
}

interface StatusesTableProps {
  title: string
  loading: boolean
  runnerStatuses: RunnerStatus[]
  selected: AccountRegion[]
  isSelected: (a: AccountRegion) => boolean
  onSelectRow: (a: AccountRegion) => void
  onSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function StatusesTable({
  title,
  loading,
  runnerStatuses,
  selected,
  isSelected,
  onSelectRow,
  onSelectAll,
}: StatusesTableProps) {
  if (!runnerStatuses.length) {
    return null
  }

  const numSelected = selected.length
  const indeterminate = numSelected > 0 && numSelected < runnerStatuses.length

  return (
    <Paper sx={{ my: 3, position: 'relative' }} elevation={2}>
      {loading && <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0 }} />}
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="h2">
          {title}
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  disabled={loading}
                  indeterminate={indeterminate}
                  checked={runnerStatuses.length > 0 && numSelected === runnerStatuses.length}
                  onChange={onSelectAll}
                  inputProps={{ 'aria-label': 'select all' }}
                />
              </TableCell>
              <TableCell>Account name</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Source revision deployed </TableCell>
              <TableCell>Latest commit (master)</TableCell>
              <TableCell>Log url</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell colSpan={2}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {runnerStatuses.map((rs) => {
              const ar = toAccountRegion(rs)
              return (
                <StatusesTableRow
                  key={`${rs.accountId},${rs.projectName}`}
                  disabled={loading}
                  selected={isSelected(ar)}
                  onSelect={() => onSelectRow(ar)}
                  runnerStatus={rs}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

type Sections = { [key in keyof typeof AccountType]: RunnerStatus[] }

interface StatusesTableRowProps {
  disabled: boolean
  runnerStatus: RunnerStatus
  selected: boolean
  onSelect: (rs: RunnerStatus) => void
}

function StatusesTableRow({ disabled, runnerStatus: rs, selected, onSelect }: StatusesTableRowProps) {
  const [openDiffModal, setOpenDiffModal] = useState(false)
  const handleOpenDiffModal = () => setOpenDiffModal(true)
  const handleCloseDiffModal = () => setOpenDiffModal(false)

  const accountName = rs.Account.name
  const latestMasterCommitHash = rs.Project.Repository.latestMasterCommitHash ?? ''
  const masterRevDeployed = latestMasterCommitHash === rs.sourceVersion
  const inProgress = rs.status === 'IN_PROGRESS'

  const onRowClick = () => {
    if (disabled || inProgress) return
    onSelect(rs)
  }

  return (
    <TableRow hover role="checkbox" selected={selected} onClick={onRowClick} sx={{ cursor: 'pointer' }}>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          disabled={disabled || inProgress}
          checked={selected}
          inputProps={{ 'aria-labelledby': accountName }}
        />
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Link component={RouterLink} to={`/accounts/${rs.accountId}/${rs.region}`}>
          {accountName} ({rs.accountId})
        </Link>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{rs.region}</TableCell>
      <TableCell>
        <Tooltip title={rs.sourceVersion ? rs.sourceVersion : ''}>
          <span>{rs.sourceVersion && rs.sourceVersion.substring(0, 7)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={latestMasterCommitHash}>
          <span>{latestMasterCommitHash.substring(0, 7)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <a href={rs.logUrl} target="_blank" rel="noreferrer">
          <AwsIcon style={{ width: '1.8rem' }} />
        </a>
      </TableCell>
      <StatusTableCell status={rs.status} />
      <TableCell>
        <Timestamp timestamp={rs.timestamp} />
      </TableCell>
      <TableCell onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <DiffModal
          open={openDiffModal}
          handleClose={handleCloseDiffModal}
          deployedCommit={rs.sourceVersion}
          repository={rs.Project.Repository}
        />
        <Button disabled={masterRevDeployed} size="small" variant="contained" onClick={handleOpenDiffModal}>
          Diff
        </Button>
      </TableCell>
      <TableCell onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <Button
          component={RouterLink}
          to={`/runners/${rs.accountId}/${rs.region}/${rs.projectName}`}
          size="small"
          variant="outlined"
          color="secondary"
          sx={{ m: 1 }}
        >
          History
        </Button>
      </TableCell>
    </TableRow>
  )
}

interface SectionToolbarProps {
  title: string
  numSelected: number
  onDeployClick: () => void
}

function SectionToolbar({ title, numSelected, onDeployClick }: SectionToolbarProps) {
  return (
    <Slide direction="up" in={numSelected > 0}>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, left: 0, right: 0 }}>
        <Toolbar variant="dense">
          <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="h2">
            {title} {numSelected} selected
          </Typography>

          <Button variant="contained" color="success" onClick={onDeployClick}>
            Deploy
          </Button>
        </Toolbar>
      </AppBar>
    </Slide>
  )
}

const toAccountRegion = (rs: RunnerStatus): AccountRegion => ({
  id: rs.accountId,
  name: rs.Account.name,
  region: rs.region,
  type: rs.Account.type,
})
