import React from 'react'
import { Box, CircularProgress } from '@mui/material'

export default function PageLoading() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
      <CircularProgress size={160} />
    </Box>
  )
}
