import { gql } from '@apollo/client'
import { Deployment, DeploymentInput } from 'src/lib/graphql/types'

export const createDeployment = gql`
  mutation createDeployment($input: DeploymentInput!) {
    deployment(input: $input) {
      id
      projectName
      sourceVersion
      accounts {
        accountId
        accountName
        accountType
        region
        status
      }
      revision
    }
  }
`

export interface CreateDeployment {
  input: DeploymentInput
}

export interface CreatedDeployment {
  deployment: Deployment
}
