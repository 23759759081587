import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Toolbar,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { GET_ACCOUNTS, GetAccountsData } from 'src/lib/graphql/accounts'
import { useAppDispatch } from 'src/lib/store'
import { setBreadcrumbs } from 'src/lib/store/features/breadcrumbsSlice'
import { Account, AccountType } from 'src/lib/graphql/types'
import PageLoading from 'src/components/PageLoading'

export default function AccountsPage() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setBreadcrumbs([{ displayName: `Accounts`, pathName: '/accounts' }]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const accounts = useQuery<GetAccountsData>(GET_ACCOUNTS)
  if (accounts.loading) return <PageLoading />
  if (!accounts.data || accounts.error) return <div>Got an error...</div>

  const sections: Sections = {
    [AccountType.WIP]: [],
    [AccountType.INTERNAL]: [],
    [AccountType.CUSTOMER_TEST]: [],
    [AccountType.CUSTOMER_PROD]: [],
  }

  const sortedAccounts = [...accounts.data.getAccounts.items].sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }

    if (a.name > b.name) {
      return 1
    }

    return 0
  })

  sortedAccounts.reduce((acc, curr) => {
    acc[curr.type].push(curr)
    return acc
  }, sections)

  return (
    <Box>
      <TableSection title="Internal" accounts={sections.INTERNAL} />
      <TableSection title="Test" accounts={sections.CUSTOMER_TEST} />
      <TableSection title="Production" accounts={sections.CUSTOMER_PROD} />
      <TableSection title="WIP" accounts={sections.WIP} />
    </Box>
  )
}

function TableSection({ title, accounts }: { title: string; accounts: Account[] }) {
  return (
    <Paper sx={{ my: 3 }} elevation={2}>
      <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
        <Typography sx={{ flex: '1 1 100%' }} variant="h5" component="h2">
          {title}
        </Typography>
      </Toolbar>

      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="accounts table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ maxWidth: 100 }}>AccountId</TableCell>
              <TableCell sx={{ maxWidth: 100 }}>Name</TableCell>
              <TableCell>Regions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((a) => (
              <TableRow key={a.id}>
                <TableCell sx={{ maxWidth: 100 }}>{a.id}</TableCell>
                <TableCell sx={{ maxWidth: 100 }}>{a.name}</TableCell>
                <TableCell>
                  <Box sx={{ mx: -1 }}>
                    {a.regions.map((r) => (
                      <Button
                        key={r}
                        component={RouterLink}
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ m: 1 }}
                        to={`/accounts/${a.id}/${r}`}
                      >
                        {r}
                      </Button>
                    ))}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

type Sections = { [key in keyof typeof AccountType]: Account[] }
