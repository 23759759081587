import React from 'react'
import { CircularProgress, TableCell, Typography } from '@mui/material'

interface Props {
  status: string
  showProgress?: boolean
}

export default function StatusTableCell({ status, showProgress = true }: Props) {
  let color = 'grey'
  let inProgress = false
  switch (status) {
    case 'IN_PROGRESS':
      color = 'blue'
      if (showProgress) inProgress = true
      break
    case 'FAILED':
      color = 'red'
      break
    case 'SUCCEEDED':
      color = 'green'
      break
    default:
      break
  }
  return (
    <TableCell>
      <Typography color={color}>
        {status} {inProgress && <CircularProgress size="1rem" />}
      </Typography>
    </TableCell>
  )
}
