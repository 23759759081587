import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import Grid from '@mui/material/Unstable_Grid2'
import PageLoading from 'src/components/PageLoading'
import Lambda from 'src/components/monitoring/Lambda'
import { GetAccountsData, GET_ACCOUNTS } from '../lib/graphql/accounts'
import { useAppDispatch } from '../lib/store'
import { setBreadcrumbs } from '../lib/store/features/breadcrumbsSlice'

export default function MonitoringLambdaPage() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { displayName: `Monitoring`, pathName: '/monitoring' },
        { displayName: `Monitoring - Lambda`, pathName: '/monitoring/lambda' },
      ])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const q = useQuery<GetAccountsData>(GET_ACCOUNTS)
  if (q.loading) return <PageLoading />
  if (!q.data || q.error) return <div>Got an error...</div>
  return (
    <Grid container spacing={2}>
      <Lambda accounts={q.data.getAccounts.items} />
    </Grid>
  )
}
