export interface RunnerStatus {
  accountId: string
  logUrl: string
  projectName: string
  region: string
  status: string
  sourceVersion: string
  timestamp: string
  Account: Account
  Project: Project
}

export interface RunnerStatusList {
  items: RunnerStatus[]
}

export interface Account {
  id: string
  name: string
  regions: string[]
  type: AccountType
}

export enum AccountType {
  WIP = 'WIP',
  INTERNAL = 'INTERNAL',
  CUSTOMER_TEST = 'CUSTOMER_TEST',
  CUSTOMER_PROD = 'CUSTOMER_PROD',
}

export interface ProjectBase {
  name: string
  type: ProjectType
}

export enum ProjectType {
  TEST = 'TEST',
  FRONTEND = 'FRONTEND',
  BACKEND = 'BACKEND',
}

export interface Project extends ProjectBase {
  LatestImage: Image
  Repository: Repository
}

export interface Image {
  tag: string
  digest: string
  timestamp: string
}

export interface Repository {
  repositoryUrl: string
  latestMasterCommitHash: string
  masterCommits: Commit[]
  Images: Image[]
}

export interface Commit {
  hash: string
  message: string
  timestamp: string
  authorName: string
  authorEmail: string
}

export interface RunnerStatusLog {
  accountId: string
  projectName: string
  region: string
  status: string
  timestamp: string
  sourceVersion: string
  logUrl: string
}

export interface RunnerStatusLogList {
  items: RunnerStatusLog[]
}

export interface DeploymentInput {
  projectName: string
  sourceVersion: string
  accounts: DeploymentAccountRegionInput[]
}

export interface DeploymentAccountRegionInput {
  accountId: string
  region: string
}

export interface Deployment {
  id: string
  projectName: string
  sourceVersion: string
  accounts: DeploymentAccountRegion[]
  revision: number
}

export interface DeploymentAccountRegion {
  accountId: string
  accountName: string
  accountType: AccountType
  region: string
  status: DeploymentStatus
}

export enum DeploymentStatus {
  STARTING = 'STARTING',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}
