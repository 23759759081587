import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
} from '@mui/material'
import { useAppDispatch } from 'src/lib/store'
import { setAlert } from 'src/lib/store/features/alertSlice'
import { Image } from 'src/lib/graphql/types'
import { startRunner } from 'src/lib/graphql/runner'
import AutocompleteTag, { TagOption } from 'src/components/AutocompleteTag'

interface Props {
  open: boolean
  handleClose: () => void
  accountId: string
  projectName: string
  region: string
  latestImage: Image
  images?: Image[]
}

const StartRunnerModal = ({ open, handleClose, accountId, projectName, region, latestImage, images }: Props) => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const [tag, setTag] = useState<TagOption | null>(null)

  const tags = [...(images ?? [])]
    .map(
      (i: Image): TagOption => ({
        ...i,
        id: i.tag,
        label: `${i.tag.substring(0, 7)} ${i.tag === latestImage.tag ? '· (master)' : ''} · ${dateTime(i.timestamp)}`,
        deployDate: date(i.timestamp),
      })
    )
    .sort((a: TagOption, b: TagOption) => {
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    })

  useEffect(() => {
    setTag(tags[0])
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [doStartRunner, { loading: deployLoading, error }] = useMutation(startRunner, {
    onCompleted: () => {
      dispatch(
        setAlert({
          open: true,
          severity: 'success',
          text: `Deploy triggered of ${projectName}`,
        })
      )
      handleClose()
    },
  })

  if (error) {
    dispatch(
      setAlert({
        open: true,
        severity: 'error',
        text: `Deploy failed of ${projectName}`,
      })
    )
  }

  const runnerStatus = {
    __typename: 'RunnerStatus',
    accountId: accountId,
    region: region,
    projectName: projectName,
  }

  const onDeploy = () => {
    return doStartRunner({
      variables: {
        accountId: accountId,
        region: region,
        projectName: projectName,
        sourceVersion: tag?.tag,
        deploymentId: '',
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify(runnerStatus),
          fields: {
            status() {
              return 'IN_PROGRESS'
            },
            timestamp() {
              return new Date().toISOString()
            },
          },
        })
      },
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={deployLoading}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {deployLoading ? <LinearProgress /> : null}
      <DialogTitle textAlign="center">Deploy project</DialogTitle>
      <DialogContent>
        <DialogContentText textAlign="center">
          Select which <b>{projectName}</b> tag to deploy to region <b>{region}</b>
        </DialogContentText>

        <AutocompleteTag loading={loading} disabled={deployLoading} tag={tag} tags={tags} onChange={setTag} />
      </DialogContent>
      <DialogActions>
        <Button disabled={deployLoading} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="success" disabled={deployLoading || !tag} onClick={onDeploy}>
          Deploy
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const date = (timestamp: string) => DateTime.fromISO(timestamp).toLocaleString(DateTime.DATE_MED)
const dateTime = (timestamp: string) => DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_MED)

export default StartRunnerModal
